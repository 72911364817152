/* eslint-disable */

import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import authConfig from "../authConfig";
import { useAuth } from "../context/Auth";
import './AzureADLogout.css'; // Import the CSS for styling

export function LogoutButton() {
  const authCtx = useAuth();
  const handleLogoutClick = () => {
    /** @todo implement */
    authCtx.logout();
  };

  return (
    <div className="button-container"> {/* Apply the same container style */}
      {/* Apply the same button style and logic */}
      <button onClick={handleLogoutClick} className="custom-button">Logout</button>
    </div>
  );
}

// function AzureADLogout() {
//   const navigate = useNavigate();

//   useEffect(() => {
//     // Check if the URL contains the expected code and state parameters
//     const urlParams = new URLSearchParams(window.location.search);
//     if (urlParams.has("code") && urlParams.has("state")) {
//       // Handle the callback here (e.g., exchange the code for tokens)
//       // After handling the callback, you can redirect the user to a specific page in your app
//       navigate("/profile"); // Replace with the desired redirect URL
//     } else {
//       // Perform cleanup tasks here (e.g., clear user data from local storage)
//       localStorage.clear(); // Replace with your cleanup logic

//       // Redirect the user to the postLogoutRedirectUri specified in the authConfig
//       window.location.href = authConfig.postLogoutRedirectUri;
//     }
//   }, [navigate]);

//   // Function to initiate Azure AD B2C logout
//   const handleLogoutClick = () => {
//     // You can trigger the logout here, if needed //! check the login counterpart, and check to do it through auth config as it has all the links
//   };

//   return (
//     <div className="button-container"> {/* Apply the same container style */}
//       {/* Apply the same button style and logic */}
//       <button onClick={handleLogoutClick} className="custom-button">Logout</button>
//     </div>
//   );
// }

// export default AzureADLogout;
