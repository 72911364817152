/* eslint-disable */

import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Link, Routes } from "react-router-dom";
import { useAuth } from "./context/Auth";
import Header from "./components/Header";
import Home from "./pages/Home";
import PrivateRoute from "./pages/PrivateRoute";
import UserProfile from "./pages/UserProfile";
import MessagesPage from "./pages/Messages";
import Footer from "./components/Footer";
import PostsPage, { MyPostsPage } from "./pages/Posts";
import NotFound from "./pages/NotFound";

const AppRouter = () => {
  const authCtx = useAuth();

  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route
          path="/profile"
          element={
            <PrivateRoute
              element={UserProfile}
              isLoggedIn={authCtx.authenticated}
            />
          }
        />
        <Route
          path="/messages"
          element={
            <PrivateRoute
              element={MessagesPage}
              isLoggedIn={authCtx.authenticated}
            />
          }
        />
        <Route
          path="/messages/:id"
          element={
            <PrivateRoute
              element={MessagesPage}
              isLoggedIn={authCtx.authenticated}
            />
          }
        />
        <Route path="/posts" element={<PostsPage />} />
        <Route
          path="/myposts"
          element={
            <PrivateRoute
              element={MyPostsPage}
              isLoggedIn={authCtx.authenticated}
            />
          }
        />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer />
    </Router>
  );
};

export default AppRouter;
