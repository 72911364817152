/* eslint-disable */

import { createContext, useContext, useEffect, useState } from "react";
import io from "socket.io-client";
import { useAuth } from "./Auth";
import { APIHost } from "../conf";

const SocketContext = createContext();

export const useSocket = () => {
    return useContext(SocketContext);
}

export const SocketContextProvider = ({children}) => {
    const [socket, setSocket] = useState(null);
    const [onlineUsers, setOnlineUsers] = useState([]);
    const authCtx = useAuth();

    useEffect(() => {
        if(authCtx.user) {
            const socket = io(`${APIHost}`, {
                query: {
                    userId: authCtx.user._id
                }
            });

            setSocket(socket);

            socket.on("getOnlineUsers", (users) => {
                setOnlineUsers(users);
            });

            return () => socket && socket.close();
        }
    }, [authCtx.user]);

    return <SocketContext.Provider value={{socket, onlineUsers}}>
        {children}
    </SocketContext.Provider>
};