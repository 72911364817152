/* eslint-disable */

const authConfig = {
  authority: 'https://verntzungauth.b2clogin.com/verntzungauth.onmicrosoft.com',
  clientId: '61e4ca2d-f48e-417d-bf1c-89923c58e2f7',
  knownAuthorities: ['https://verntzungauth.b2clogin.com/verntzungauth.onmicrosoft.com'],
  // redirectUri: 'http://localhost:3000/auth-response', // Make sure this matches your Azure AD B2C application settings.
  redirectUri: 'https://vernet-zung.com/auth-response', // Make sure this matches your Azure AD B2C application settings.
  postLogoutRedirectUri: 'https://verntzungauth.b2clogin.com/verntzungauth.onmicrosoft.com/oauth2/v2.0/logout?p=b2c_1_auth.vernetzung',
  validateAuthority: true,
  cacheLocation: 'localStorage',
  storeAuthStateInCookie: false,
  navigateToLoginRequestUrl: true,
  authorizationEndpoint: 'https://verntzungauth.b2clogin.com/verntzungauth.onmicrosoft.com/B2C_1_auth.vernetzung/oauth2/v2.0/authorize',
  tokenEndpoint: 'https://verntzungauth.b2clogin.com/verntzungauth.onmicrosoft.com/B2C_1_auth.vernetzung/oauth2/v2.0/token',
  endSessionEndpoint: 'https://verntzungauth.b2clogin.com/verntzungauth.onmicrosoft.com/B2C_1_auth.vernetzung/oauth2/v2.0/logout',
  jwksUri: 'https://verntzungauth.b2clogin.com/verntzungauth.onmicrosoft.com/discovery/v2.0/keys?p=b2c_1_auth.vernetzung',
  responseModesSupported: ['query', 'fragment', 'form_post'],
  responseTypesSupported: [
    'code',
    'code id_token',
    'code token',
    'code id_token token',
    'id_token',
    'id_token token',
    'token',
    'token id_token',
  ],
  scopesSupported: ['openid'],
  subjectTypesSupported: ['pairwise'],
  idTokenSigningAlgValuesSupported: ['RS256'],
  tokenEndpointAuthMethodsSupported: ['client_secret_post', 'client_secret_basic'],
  claimsSupported: [
    'country',
    'name',
    'given_name',
    'jobTitle',
    'newUser',
    'idp',
    'sub',
    'tfp',
    'iss',
    'iat',
    'exp',
    'aud',
    'acr',
    'nonce',
    'auth_time',
  ],
};

export default authConfig;
