/* eslint-disable */

// Header.js
import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import {
  CssBaseline,
  Drawer,
  List,
  ListItem,
  ListItemText,
  AppBar,
  Toolbar,
  Button,
  IconButton,
  Avatar,
  Box,
} from "@mui/material";
import { styled } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";

import AzureADLogin from "./AzureADLogin";
import AzureADRegister from "./AzureADRegister";
import { LogoutButton } from "./AzureADLogout";
import { useAuth } from "../context/Auth";

// Import your button styles
import "./AzureADLogout.css";

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  backgroundColor: "#000",
  zIndex: 99999,
}));

const DrawerMenu = styled(Drawer)(({ theme }) => ({
  width: "250px",
  flexShrink: 0,
}));

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const authCtx = useAuth();
  // const [userProfileImage, setUserProfileImage] = useState(authCtx.);

  // useEffect(() => {
  //   if (authCtx.authenticated) {
  //     // Fetch the user's profile image from your backend
  //     // This is a placeholder URL, replace it with your actual API endpoint
  //     fetch('/api/user/profile/image')
  //       .then(response => {
  //         if (!response.ok) {
  //           throw new Error('Network response was not ok');
  //         }
  //         return response.json();
  //       })
  //       .then(data => setUserProfileImage(data.image))
  //       .catch(error => {
  //         console.error('There has been a problem with your fetch operation:', error);
  //         // Set a default image in case of error
  //         setUserProfileImage(null);
  //       });
  //   }
  // }, [authCtx.authenticated]);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <>
      <CssBaseline />

      <StyledAppBar position="sticky" style={{ top: 0, zIndex: 1 }}>
        <Toolbar>
          <IconButton color="inherit" aria-label="menu" onClick={toggleMenu}>
            <MenuIcon />
          </IconButton>
          <img
            src="https://i.imgur.com/TWaHdIT.jpg"
            alt="Your Logo"
            className="css-zecgw"
            style={{ width: "40px", height: "40px" }}
          />
          <Box sx={{ flex: 1 }}></Box>
          <Button
            component={Link}
            to="/"
            color="inherit"
            className="custom-button"
          >
            Home
          </Button>
          <Box sx={{ width: "20px" }}></Box> {/* Add space */}
          {!authCtx.authenticated ? (
            <>
              <AzureADLogin />
              <Box sx={{ width: "20px" }}></Box> {/* Add space */}
              <AzureADRegister />
            </>
          ) : (
            <>
              <LogoutButton />
              <Box sx={{ width: "20px" }}></Box> {/* Add space */}
              <Button
                component={Link}
                to="/messages"
                color="inherit"
                className="custom-button"
              >
                Messages
              </Button>
              <Box sx={{ width: "20px" }}></Box> {/* Add space */}
              <IconButton
                component={Link}
                to="/profile"
                color="inherit"
                className="custom-button"
              >
                <Avatar
                  alt="Profile Picture"
                  src={authCtx.user?.avatar || null}
                />
              </IconButton>
            </>
          )}
        </Toolbar>
      </StyledAppBar>

      <DrawerMenu open={menuOpen} onClose={toggleMenu}>
        <List>
          <ListItem button component={Link} to="/">
            <ListItemText primary="Home" />
          </ListItem>
          <ListItem button component={Link} to="/opportunities">
            <ListItemText primary="Opportunities" />
          </ListItem>
          <ListItem button component={Link} to="/network">
            <ListItemText primary="Network" />
          </ListItem>
          <ListItem button component={Link} to="/messages">
            <ListItemText primary="Messages" />
          </ListItem>
          <ListItem button component={Link} to="/profile">
            <ListItemText primary="Me" />
          </ListItem>
          <ListItem button component={Link} to="/posts">
            <ListItemText primary="Posts" />
          </ListItem>
          <ListItem button component={Link} to="/myposts">
            <ListItemText primary="My Posts" />
          </ListItem>
          <ListItem button component={Link} to="/notifications">
            <ListItemText primary="Notifications" />
          </ListItem>
        </List>
      </DrawerMenu>
    </>
  );
};

export default Header;
