/* eslint-disable */

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Typography, Grid, Card, CardContent, CardActions, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useSpring, animated } from '@react-spring/web';

const ParticleCanvas = styled('canvas')({
  position: 'fixed',
  top: 0,
  left: 0,
  pointerEvents: 'none',
  zIndex: 0,
  width: '100vw',
  height: '100vh',
});

const RootContainer = styled('div')(({ theme }) => ({
  width: '100%',
  height: '100vh',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',
  overflow: 'hidden',
  
}));

const WelcomeText = styled(Typography)(({ theme }) => ({
  position: 'relative',
  zIndex: 9999,
  fontSize: '5.5rem',
  marginBottom: theme.spacing(10), 
  marginTop: theme.spacing(35),
}));

const DescriptionText = styled(Typography)(({ theme }) => ({
  position: 'relative',
  zIndex: 9999,
  fontFamily: 'Poppins, sans-serif',
  marginTop: theme.spacing(6),
  textAlign: 'center',
  color: 'white',
  width: '80%',
  marginLeft: 'auto',
  marginRight: 'auto',
  fontSize: '1.5rem',
  marginBottom: theme.spacing(10), // Increase the bottom margin to add more space below the header
}));

const GridContainer = styled(Grid)(({ theme }) => ({
  width: '100%',
  margin: 'auto',
  zIndex: 9999,
  paddingTop: '80px',
}));

const PostCard = styled(Card)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  borderRadius: theme.spacing(2),
  transition: 'box-shadow 0.3s ease-in-out',
  '&:hover': {
    boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)',
  },
}));

const PostHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: theme.spacing(1),
}));

const PostImage = styled('img')(({ theme }) => ({
  width: 50,
  height: 50,
  borderRadius: '50%',
  marginRight: theme.spacing(2),
}));

const Home = () => {
  const [posts, setPosts] = useState([]);
  const [scrollOffset, setScrollOffset] = useState(0);

  const springProps = useSpring({ transform: `translateY(-${scrollOffset}px)`, from: { transform: 'translateY(0px)' } });

  useEffect(() => {

    const canvas = document.getElementById('particle-canvas');
    const ctx = canvas.getContext('2d');

    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;

    const particles = [];

    function createParticles(e) {
      const xPos = e.x;
      const yPos = e.y;

      for (let i = 0; i < 15; i++) {
        particles.push({
          x: xPos,
          y: yPos,
          size: Math.random() * 5 + 1,
          speedX: Math.random() * 3 - 1.5,
          speedY: Math.random() * 3 - 1.5,
          color: `rgba(255, 255, 255, ${Math.random()})`,
        });
      }
    }

    function animateParticles() {
      ctx.fillStyle = 'rgba(0, 0, 0, 0.1)';
      ctx.fillRect(0, 0, canvas.width, canvas.height);

      for (let i = 0; i < particles.length; i++) {
        const particle = particles[i];

        particle.x += particle.speedX;
        particle.y += particle.speedY;
        particle.size -= 0.05;

        const gradient = ctx.createRadialGradient(particle.x, particle.y, 0, particle.x, particle.y, particle.size);
        gradient.addColorStop(0, 'transparent');
        gradient.addColorStop(1, particle.color);

        ctx.fillStyle = gradient;
        ctx.beginPath();
        ctx.arc(particle.x, particle.y, particle.size, 0, Math.PI * 2);
        ctx.fill();

        if (particle.size <= 0.2) {
          particles.splice(i, 1);
          i--;
        }
      }

      requestAnimationFrame(animateParticles);
    }

    document.addEventListener('mousemove', createParticles);
    animateParticles();
  }, []);

  const handleScroll = () => {
    setScrollOffset(window.scrollY);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <RootContainer>
      <ParticleCanvas id="particle-canvas" />

      <animated.div style={springProps}>
        <WelcomeText variant="h1" gutterBottom color="white" align="center">
          Welcome to Vernetzung
        </WelcomeText>
        <DescriptionText variant="body1">
          VZ is a social networking platform for students to connect with peers globally and find co-founders, collaborators, and communicate with like-minded peers. This is specifically designed for undergraduate and high school students with high spirits and excellent ideas to spread their influence on a large scale.
        </DescriptionText>
      </animated.div>
      <GridContainer container spacing={3}>
        {posts.length > 0 ? (
          posts.map((post) => (
            <Grid key={post._id} item xs={12} sm={6} md={4}>
              <PostCard>
                <CardContent>
                  <PostHeader>
                    <PostImage src={post.profileImage} alt="Profile" />
                    <div>
                      <Typography variant="h5">{post.fullName}</Typography>
                      <Typography variant="subtitle1">{post.jobTitle} | {post.company}</Typography>
                      <Typography variant="subtitle2">{post.location}</Typography>
                    </div>
                  </PostHeader>
                  <Typography variant="h6" gutterBottom>
                    {post.title}
                  </Typography>
                  <Typography variant="body1">{post.content}</Typography>
                </CardContent>
                <CardActions>
                  <Button color="error">Like</Button>
                  <Button color="primary">Comment</Button>
                  <Button color="success">Share</Button>
                </CardActions>
              </PostCard>
            </Grid>
          ))
        ) : null}
      </GridContainer>
    </RootContainer>
  );
};

export default Home;

// alternate animation
{/*
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Container, Typography, Grid, Card, CardContent, CardActions, Button } from '@mui/material';
import { styled } from '@mui/material/styles';

const ParticleCanvas = styled('canvas')({
  position: 'absolute',
  top: 0,
  left: 0,
  pointerEvents: 'none',
});

const RootContainer = styled('div')(({ theme }) => ({
  width: '100%',
  height: '100vh',
  marginTop: 0,
  background: 'black', // Set background color to black
  position: 'relative',
}));

const PostCard = styled(Card)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  borderRadius: theme.spacing(2),
  transition: 'box-shadow 0.3s ease-in-out',
  '&:hover': {
    boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)',
  },
}));

const PostHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: theme.spacing(1),
}));

const PostImage = styled('img')(({ theme }) => ({
  width: 50,
  height: 50,
  borderRadius: '50%',
  marginRight: theme.spacing(2),
}));

// ... (previous imports)

const Home = () => {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    // Fetch posts from backend API (replace the API URL with your backend URL)
    // axios.get(`${APIHost}/post`)
    //   .then((response) => {
    //     setPosts(response.data);
    //   })
    //   .catch((error) => {
    //     console.error('Error fetching posts:', error);
    //   });

    // Set up particle animation
    const canvas = document.getElementById('particle-canvas');
    const ctx = canvas.getContext('2d');

    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;

    const particles = [];

    function createParticles(e) {
      const xPos = e.x;
      const yPos = e.y;

      for (let i = 0; i < 15; i++) {
        particles.push({
          x: xPos,
          y: yPos,
          size: Math.random() * 3 + 1,
          speedX: Math.random() * 3 - 1.5,
          speedY: Math.random() * 3 - 1.5,
          color: `rgba(255, 255, 255, 0.8)`, // White with higher opacity for sunlight effect
        });
      }
    }

    function animateParticles() {
      ctx.fillStyle = 'rgba(0, 0, 0, 0.1)';
      ctx.fillRect(0, 0, canvas.width, canvas.height);

      for (let i = 0; i < particles.length; i++) {
        const particle = particles[i];

        particle.x += particle.speedX;
        particle.y += particle.speedY;
        particle.size -= 0.05;

        const gradient = ctx.createRadialGradient(particle.x, particle.y, 0, particle.x, particle.y, particle.size);
        gradient.addColorStop(0, 'transparent');
        gradient.addColorStop(1, particle.color);

        ctx.fillStyle = gradient;
        ctx.beginPath();
        ctx.arc(particle.x, particle.y, particle.size, 0, Math.PI * 2);
        ctx.fill();

        if (particle.size <= 0.2) {
          particles.splice(i, 1);
          i--;
        }
      }

      requestAnimationFrame(animateParticles);
    }

    document.addEventListener('mousemove', createParticles);
    animateParticles();

    return () => {
      document.removeEventListener('mousemove', createParticles);
    };
  }, []);



  return (
    <RootContainer>
      <ParticleCanvas id="particle-canvas" />
      <Typography variant="h1" gutterBottom color="white" align="center">
        Welcome to Vernetzung
      </Typography>
      <Grid container spacing={3}>
        {posts.length > 0 ? (
          posts.map((post) => (
            <Grid key={post._id} item xs={12} sm={6} md={4}>
              <PostCard>
                <CardContent>
                  <PostHeader>
                    <PostImage src="img/profile.jpg" alt="Profile" />
                    <div>
                      <Typography variant="h5">Full Name</Typography>
                      <Typography variant="subtitle1">Job Title | Company</Typography>
                      <Typography variant="subtitle2">Location</Typography>
                    </div>
                  </PostHeader>
                  <Typography variant="h6" gutterBottom>
                    {post.title}
                  </Typography>
                  <Typography variant="body1">{post.content}</Typography>
                </CardContent>
                <CardActions>
                  <Button color="error">Like</Button>
                  <Button color="primary">Comment</Button>
                  <Button color="success">Share</Button>
                </CardActions>
              </PostCard>
            </Grid>
          ))
        ) : null}
      </Grid>
    </RootContainer>
  );
};

export default Home;

*/}
