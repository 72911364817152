import styled from "@emotion/styled";
import { Typography } from "@mui/material";

const StyledFooter = styled("footer")(({ theme }) => ({
  backgroundColor: "#222",
  padding: theme.spacing(2),
  marginBlockStart: "auto",
  color: "#fff",
  textAlign: "center",
}));

const Footer = () => {
  return (
    <StyledFooter>
      <Typography variant="body2">
        Copyright © {new Date().getFullYear()} Vernetzung
      </Typography>
    </StyledFooter>
  );
};

export default Footer;
