/* eslint-disable */

import React, { useState, useEffect, useRef } from "react";
import { useAuth } from "../context/Auth";
import {
  Container,
  Grid,
  Paper,
  List,
  ListItem,
  ListItemText,
  Box,
  Typography,
  Link,
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  ListItemSecondaryAction,
  Avatar,
} from "@mui/material";
import MessageIcon from "@mui/icons-material/Message";
import {
  Route,
  Routes,
  useParams,
  Link as RouterLink,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { useSocket } from "../context/Socket";
import messageSound from "../sounds/newmsg.mp3";
import SendIcon from "@mui/icons-material/Send";
import "../scss/messages.scss";

const Sidebar = ({ openDialog, activeConversation }) => {
  const authCtx = useAuth();

  return (
    <List>
      {authCtx.conversations.map((user, index) => (
        <ListItem
          key={index}
          button
          component={RouterLink}
          to={`/messages/${user._id}`}
        >
          <Box>
            <ListItemText primary={user.participants[0].name} />
            {(user.lastMessage.sender || "").length > 0 && (
              <ListItemText
                primary={`${
                  user.lastMessage.sender === authCtx.user._id
                    ? authCtx.user.name
                    : user.participants && user.participants.length > 0
                    ? user.participants[0].name
                    : ""
                }: ${user.lastMessage.text}`}
              />
            )}
          </Box>
        </ListItem>
      ))}
      <ListItem component={Button} onClick={openDialog}>
        <Box>
          <ListItemText primary={"Add people"} />
        </Box>
      </ListItem>
    </List>
  );
};

const ChatWindow = () => {
  return (
    <Box p={2}>
      <Typography variant="h6">User 1</Typography>
      {/* Add your chat messages or chat components here */}
    </Box>
  );
};

// function MessagesPage() {
//   const authCtx = useAuth();
//   const [activeConversation, setActiveConversation] = useState(null);
//   const { id } = useParams();

//   const navigateTo = useNavigate();

//   const [messages, setMessages] = useState([]);
//   const [newMessage, setNewMessage] = useState(null);

//   const { socket } = useSocket();

//   useEffect(() => {
//     authCtx.initUserConversations();
//   }, []);

//   useEffect(() => {
//     console.log("Ok");

//     socket.on("newMessage", (message) => {
//       console.log("msg ::: ", message);
//       if (activeConversation) {
//         if (activeConversation._id === message.conversationId) {
//           setMessages((prev) => [...prev, message]);
//         }
//       }
//       if (!document.hasFocus()) {
//         try {
//           const sound = new Audio(messageSound);
//           try {
//             sound.play().catch((err) => {});
//           } catch (error) {}
//         } catch (error) {}
//       }

//       const updatedConversations = authCtx.conversations.map((conversation) => {
//         if (conversation._id === message.conversationId) {
//           return {
//             ...conversation,
//             lastMessage: {
//               text: message.text,
//               sender: message.sender,
//             },
//           };
//         }
//         return conversation;
//       });
//       authCtx.setConversations(updatedConversations, false);
//     });

//     return () => socket.off("newMessage");
//   }, [socket, activeConversation, authCtx.setConversations]);

//   useEffect(() => {
//     console.log("ID ::: ", id, authCtx.conversations);
//     authCtx.conversations.map((c) => {
//       console.log("c :: ", c);
//       if (`${c._id}` === id) {
//         setActiveConversation(c);
//       }
//     });
//   }, [id, authCtx.conversations]);

//   useEffect(() => {
//     /** fetch messages */
//     console.log("Act ::: ", activeConversation);
//     if (activeConversation) {
//       authCtx
//         .fetchMessageHistory(activeConversation.participants[0]._id)
//         .then(async (data) => {
//           // let r = await data.json();
//           console.log("r :: ", data);
//           setMessages(data);
//         });
//     }
//   }, [activeConversation]);

//   useEffect(() => {
//     if (authCtx.conversations.length === 0) {
//       // const mockConversation = {
//       //   mock: true,
//       //   lastMessage: {
//       //     text: "Hi",
//       //     sender: "User",
//       //   },
//       //   _id: Date.now(),
//       //   participants: [
//       //     {
//       //       _id: "65558180bd09d1695836af65",
//       //       name: "Ashu Sharma",
//       //     },
//       //   ],
//       // };
//       // authCtx.setConversations([mockConversation]);
//     }
//   }, [authCtx.conversations]);

//   const handleSendMessage = async (e) => {
//     if (activeConversation) {
//       console.log(activeConversation);
//       let msgdata = await authCtx.sendMessage(
//         activeConversation.participants[0]._id,
//         newMessage,
//         undefined
//       );
//       if (msgdata) {
//         console.log("msg ::: ", msgdata);
//         setMessages((pr) => [...pr, msgdata]);
//         const updatedConversations = authCtx.conversations.map(
//           (conversation) => {
//             if (conversation._id === msgdata.conversationId) {
//               return {
//                 ...conversation,
//                 lastMessage: {
//                   text: msgdata.text,
//                   sender: msgdata.sender,
//                 },
//               };
//             }
//             return conversation;
//           }
//         );
//         console.log(updatedConversations.length)
//         authCtx.setConversations(updatedConversations, false);
//       }
//     }
//   };

//   const [open, setOpen] = useState(false);

//   const handleOpen = () => {
//     setOpen(true);
//   };

//   const handleClose = () => {
//     setOpen(false);
//   };

//   useEffect(() => {
//     if (open) {
//       authCtx.loadSuggestedProfiles();
//     }
//   }, [open]);

//   const handleFollowProfile = async (profile_id) => {
//     await authCtx.followProfile(profile_id);
//   };

//   const handleStartConversation = (profile_id, name) => {
//     const mockConversation = {
//       mock: true,
//       lastMessage: {
//         text: "",
//         sender: "",
//       },
//       _id: Date.now(),
//       participants: [
//         {
//           _id: profile_id,
//           name: name,
//         },
//       ],
//     };

//     authCtx.setConversations([...authCtx.conversations, mockConversation]);
//     navigateTo(`/messages/${mockConversation._id}`);
//     setOpen(false);
//   };

//   return (
//     <>
//       <Container>
//         <h1>Messages</h1>

//         <div className="" style={{ marginTop: 12 }}>
//           <Container>
//             <Grid container spacing={2}>
//               {/* Sidebar */}
//               <Grid item xs={3}>
//                 <Paper style={{ height: "100vh", overflowY: "auto" }}>
//                   <Sidebar activeConversation={activeConversation} openDialog={handleOpen} />
//                 </Paper>
//               </Grid>

//               {/* Chat Window */}
//               <Grid item xs={9}>
//                 <Paper style={{ height: "100vh", overflowY: "auto" }}>
//                   <Box p={2} height="100%">
//                     {/* Chat Messages */}
//                     <List
//                       style={{
//                         maxHeight: "calc(100% - 56px)",
//                         overflowY: "auto",
//                       }}
//                     >
//                       {(messages || []).map((message, index) => (
//                         <ListItem key={index}>
//                           <Typography variant="body1">
//                             <strong>
//                               {`${
//                                 message.sender === authCtx.user._id
//                                   ? authCtx.user.name
//                                   : activeConversation.participants &&
//                                     activeConversation.participants.length > 0
//                                   ? activeConversation.participants[0].name
//                                   : ""
//                               }`}
//                               :
//                             </strong>{" "}
//                             {message.text}
//                           </Typography>
//                         </ListItem>
//                       ))}
//                     </List>

//                     {/* Text Input and Send Button */}
//                     {activeConversation && <Box display="flex" alignItems="center" mt={2}>
//                       <TextField
//                         fullWidth
//                         variant="outlined"
//                         label="Type your message..."
//                         value={newMessage}
//                         onChange={(e) => setNewMessage(e.target.value)}
//                       />
//                       <Button
//                         variant="contained"
//                         color="primary"
//                         onClick={handleSendMessage}
//                         style={{ marginLeft: "8px" }}
//                       >
//                         Send
//                       </Button>
//                     </Box>}
//                   </Box>
//                 </Paper>
//               </Grid>
//             </Grid>
//           </Container>
//         </div>
//       </Container>
//       <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
//         <DialogTitle>Add persons</DialogTitle>
//         <DialogContent dividers>
//           <List>
//             {(authCtx.suggested_profiles || []).map((item, index) => (
//               <ListItem key={index}>
//                 <ListItemText primary={item.name} />
//                 <ListItemSecondaryAction>
//                   <IconButton
//                     onClick={(e) =>
//                       handleStartConversation(item._id, item.name)
//                     }
//                     style={{ marginRight: 12 }}
//                     edge="end"
//                     aria-label="message"
//                   >
//                     <MessageIcon />
//                   </IconButton>
//                   <Button
//                     onClick={(e) => handleFollowProfile(item._id)}
//                     aria-label="follow"
//                   >
//                     {(authCtx.user.following || []).includes(item._id)
//                       ? "Unfollow"
//                       : "Follow"}
//                   </Button>
//                 </ListItemSecondaryAction>
//               </ListItem>
//             ))}
//           </List>
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={handleClose} color="primary">
//             Close
//           </Button>
//         </DialogActions>
//       </Dialog>
//     </>
//   );
// }

function SidebarContact({ conversation, isActive }) {
  const authCtx = useAuth();
  return (
    <RouterLink
      to={`/messages/${conversation._id}`}
      className={"contact" + (isActive ? " active" : "")}
    >
      <div className="inner">
        <div className="avatar">
          <Avatar />
        </div>
        <div className="info">
          <div className="name">
            <Typography variant="body1">
              {conversation.participants[0].name}
            </Typography>
          </div>
          {(conversation.lastMessage.sender || "").length > 0 && (
            <div className="last-chat">
              <Typography variant="body2">{`${
                conversation.lastMessage.sender === authCtx.user._id
                  ? authCtx.user.name
                  : conversation.participants &&
                    conversation.participants.length > 0
                  ? conversation.participants[0].name
                  : ""
              }: ${conversation.lastMessage.text}`}</Typography>
            </div>
          )}
        </div>
      </div>
    </RouterLink>
  );
}

function ChatOutgoing({ message }) {
  return (
    <div className="chat-outgoing-wrapper">
      <div className="chat-outgoing">
        <Typography variant="body1">{message.text}</Typography>
      </div>
    </div>
  );
}

function ChatIncoming({ message }) {
  return (
    <div className="chat-incoming-wrapper">
      <div className="chat-incoming">
        <Typography variant="body1">{message.text}</Typography>
      </div>
    </div>
  );
}

function MessagesPage() {
  const authCtx = useAuth();
  const [activeConversation, setActiveConversation] = useState(null);

  const { id } = useParams();
  const chatWindowRef = useRef(null);

  const navigateTo = useNavigate();

  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState(null);

  const { socket } = useSocket();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    authCtx.initUserConversations();
  }, []);

  useEffect(() => {
    socket.on("newMessage", (message) => {
      if (activeConversation) {
        if (activeConversation._id === message.conversationId) {
          setMessages((prev) => [...prev, message]);
        }
      }
      if (!document.hasFocus()) {
        try {
          const sound = new Audio(messageSound);
          try {
            sound.play().catch((err) => {});
          } catch (error) {}
        } catch (error) {}
      }

      const updatedConversations = authCtx.conversations.map((conversation) => {
        if (conversation._id === message.conversationId) {
          return {
            ...conversation,
            lastMessage: {
              text: message.text,
              sender: message.sender,
            },
          };
        }
        return conversation;
      });
      authCtx.setConversations(updatedConversations, false);
    });

    return () => socket.off("newMessage");
  }, [socket, activeConversation, authCtx.setConversations]);

  useEffect(() => {
    console.log("msgid ::: ", id)
    if (id) {
      if (id.endsWith("__profile_id")) {
        let i = id.replace("__profile_id", "");

        let isFound = false;

        authCtx.conversations.map((c) => {
          if (c.participants.length > 0) {
            console.log(i, c.participants[0]._id);
            if (`${c.participants[0]._id}` === i) {
              console.log("Ok?");
              isFound = true;
              navigateTo(`/messages/${c._id}`);
              // setActiveConversation(c);
            }
          }
        });

        console.log("isFOund ::: ", isFound);

        if (!isFound) {
          let name = searchParams.get("name");
          handleStartConversation(i, name);
        }
      } else {
        authCtx.conversations.map((c) => {
          if (`${c._id}` === id) {
            setActiveConversation(c);
          }
        });
      }
    }
  }, [id, authCtx.conversations]);

  useEffect(() => {
    /** fetch messages */
    if (activeConversation) {
      authCtx
        .fetchMessageHistory(activeConversation.participants[0]._id)
        .then(async (data) => {
          setMessages(data);
        });
    }
  }, [activeConversation]);

  useEffect(() => {
    if (authCtx.conversations.length === 0) {
      // const mockConversation = {
      //   mock: true,
      //   lastMessage: {
      //     text: "Hi",
      //     sender: "User",
      //   },
      //   _id: Date.now(),
      //   participants: [
      //     {
      //       _id: "65558180bd09d1695836af65",
      //       name: "Ashu Sharma",
      //     },
      //   ],
      // };
      // authCtx.setConversations([mockConversation]);
    }
  }, [authCtx.conversations]);

  const handleSendMessage = async (e) => {
    if (activeConversation) {
      console.log(activeConversation);
      let msgdata = await authCtx.sendMessage(
        activeConversation.participants[0]._id,
        newMessage,
        undefined
      );
      if (msgdata) {
        setMessages((pr) => [...(pr || []), msgdata]);
        const updatedConversations = authCtx.conversations.map(
          (conversation) => {
            if (conversation._id === msgdata.conversationId) {
              return {
                ...conversation,
                lastMessage: {
                  text: msgdata.text,
                  sender: msgdata.sender,
                },
              };
            }
            return conversation;
          }
        );

        if(activeConversation.mock) {
          authCtx.initUserConversations();
        }

        console.log(updatedConversations.length);
        authCtx.setConversations(updatedConversations, false);
      }
    }
  };

  useEffect(() => {
    if (chatWindowRef.current) {
      chatWindowRef.current.scrollTop = chatWindowRef.current.scrollHeight;
    }
  }, [messages]);

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (open) {
      authCtx.loadSuggestedProfiles();
    }
  }, [open]);

  const handleFollowProfile = async (profile_id) => {
    await authCtx.followProfile(profile_id);
  };

  const handleStartConversation = (profile_id, name) => {
    const mockConversation = {
      mock: true,
      lastMessage: {
        text: "",
        sender: "",
      },
      _id: Date.now(),
      participants: [
        {
          _id: profile_id,
          name: name,
        },
      ],
    };

    authCtx.setConversations([...authCtx.conversations, mockConversation]);
    navigateTo(`/messages/${mockConversation._id}`);
    setOpen(false);
  };

  const handleKeyUp = (e) => {
    if (e.key === "Enter") {
      handleSendMessage();
      e.target.value = "";
    }
  };

  return (
    <>
      <div className="messages-page">
        <div className="inner">
          <div className="sidebar">
            <div className="inner">
              <div className="sidebar-hdr">
                <Typography variant="body1" fontWeight={600}>
                  MESSAGES
                </Typography>
              </div>

              <div className="sidebar-chats">
                {authCtx.conversations.map((c, i) => {
                  return (
                    <SidebarContact
                      key={i}
                      conversation={c}
                      isActive={(activeConversation || {})._id === c._id}
                    />
                  );
                })}
              </div>
            </div>
          </div>
          {/* <ListItem key={index}>
                  <Typography variant="body1">
                    <strong>
                      {`${
                        message.sender === authCtx.user._id
                          ? authCtx.user.name
                          : activeConversation.participants &&
                            activeConversation.participants.length > 0
                          ? activeConversation.participants[0].name
                          : ""
                      }`}
                      :
                    </strong>{" "}
                    {message.text}
                  </Typography>
                </ListItem> */}
          <div className="body">
            {activeConversation ? (
              <div className="chat-body">
                <div className="chats-container" ref={chatWindowRef}>
                  {(messages || []).map((message, index) => {
                    return (
                      <React.Fragment key={message._id}>
                        {message.sender === authCtx.user._id ? (
                          <ChatOutgoing message={message} />
                        ) : (
                          <ChatIncoming message={message} />
                        )}
                      </React.Fragment>
                    );
                  })}
                </div>
                <div className="chat-controls">
                  <div className="inner">
                    <input
                      onChange={(e) => setNewMessage(e.target.value)}
                      onKeyUp={handleKeyUp}
                      defaultValue={newMessage}
                      placeholder={`Message ${
                        activeConversation.participants.length > 0
                          ? activeConversation.participants[0].name.split(
                              " "
                            )[0]
                          : ""
                      }`}
                      type="text"
                    />
                    <IconButton onClick={handleSendMessage}>
                      <SendIcon />
                    </IconButton>
                  </div>
                </div>
              </div>
            ) : (
              <div className="msg-body">
                <Typography variant="h3">
                  Select or start conversations
                </Typography>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default MessagesPage;
