/* eslint-disable */

import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { Component } from "react";
import ReactQuill, { Quill } from "react-quill";
import quillEmoji from "quill-emoji";
import "react-quill/dist/quill.snow.css";
import {
  Typography,
  CircularProgress,
  Avatar,
  Button,
  Menu,
  MenuItem,
  Modal,
  IconButton,
} from "@mui/material";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import { styled } from "@mui/material/styles";
import AvatarEditor from "react-avatar-editor";
import { useDropzone } from "react-dropzone";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import PencilIcon from "@mui/icons-material/Edit"
import { useAuth } from "../context/Auth";
import "../scss/user_profile.scss";

const { EmojiBlot, ShortNameEmoji, ToolbarEmoji, TextAreaEmoji } = quillEmoji;

// Quill.register(
//   {
//     "formats/emoji": EmojiBlot,
//     "modules/emoji-shortname": ShortNameEmoji,
//     "modules/emoji-toolbar": ToolbarEmoji,
//     "modules/emoji-textarea": TextAreaEmoji,
//   },
//   true
// );

// const RootContainer = styled("div")(({ theme }) => ({
//   padding: theme.spacing(3),
//   display: "flex",
//   flexDirection: "column",
//   alignItems: "center",
//   justifyContent: "flex-start",
//   height: "100vh",
// }));

// const CloseButton = styled(IconButton)({
//   position: "absolute",
//   top: "10px",
//   right: "10px",
//   backgroundColor: "transparent",
//   color: "rgba(255, 255, 255, 0.8)",
//   "&:hover": {
//     backgroundColor: "transparent",
//   },
// });

const AvatarUploaderInputContainer = styled("div")({
  position: "absolute",
  width: "100%",
  height: "100%",
  border: "none",
  top: 0,
  left: 0,
  // pointerEvents: "none",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  opacity: 0,
  "&:hover": {
    background: "rgba(0, 0, 0, 0.8)",
    opacity: 1
  },
  borderRadius: 300,
  cursor: "pointer",
  "> input": {
    position: "absolute",
    opacity: 0,
    width: "100%",
    height: "100%"
  }
});

// const ImageEditorContainer = styled("div")({
//   position: "absolute",
//   top: "50%",
//   left: "50%",
//   transform: "translate(-50%, -50%)",
//   width: "50%",
//   height: "50%",
//   backgroundColor: "rgba(0, 0, 0, 0.5)",
// });

// const SaveButtonContainer = styled("div")({
//   marginTop: "20px",
// });

// const handleDrop = (acceptedFiles, setImage, openModal) => {
//   if (acceptedFiles && acceptedFiles.length > 0) {
//     const file = acceptedFiles[0];
//     setImage(file);
//     openModal();
//   }
// };

// const UserProfile = () => {
//   const authCtx = useAuth();
//   const [user, setUser] = useState(authCtx.user || null);
//   const [loading, setLoading] = useState(true);
//   const [anchorEl, setAnchorEl] = useState(null);
//   const [image, setImage] = useState(null);
//   const [editor, setEditor] = useState(null);
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const [editorState, setEditorState] = useState("");
//   const [editedField, setEditedField] = useState("");
//   const { getRootProps, getInputProps } = useDropzone({
//     onDrop: handleDrop,
//   });

//   useEffect(() => {
//     // const fetchUserProfile = async () => {
//     //   try {
//     //     const response = await axios.get("/api/user");
//     //     setUser(response.data);
//     //     setLoading(false);
//     //   } catch (error) {
//     //     console.error("Error fetching user profile:", error);
//     //     setLoading(false);
//     //   }
//     // };

//     // fetchUserProfile();
//     setUser(authCtx.user)
//   }, [authCtx.user]);

//   useEffect(() => {
//     if(user) {
//       setLoading(false);
//     }
//     else
//     {
//       setLoading(true);
//     }
//   }, [user]);

//   const modules = {
//     toolbar: [
//       [{ font: [] }, { header: [] }],
//       ["bold", "italic", "underline", "strike", "blockquote", "code-block"],
//       [{ color: [] }, { background: [] }],
//       [
//         { list: "ordered" },
//         { list: "bullet" },
//         { indent: "-1" },
//         { indent: "+1" },
//       ],
//       [{ align: [] }],
//       ["emoji"],
//       ["link", "image"],
//       ["clean"],
//     ],
//     "emoji-toolbar": true,
//     "emoji-textarea": true,
//     "emoji-shortname": true,
//   };

//   const handleMenuOpen = (event) => {
//     setAnchorEl(event.currentTarget);
//   };

//   const handleMenuClose = (event, option) => {
//     setAnchorEl(null);
//     if (option === "ChangeProfilePicture") {
//       openDropzone();
//     }
//   };

//   const openDropzone = () => {
//     const dropzoneInput = document.getElementById("dropzone-input");
//     if (dropzoneInput) {
//       dropzoneInput.click();
//     }
//   };

//   const closeModal = () => {
//     setIsModalOpen(false);
//   };

//   const openModal = () => {
//     setIsModalOpen(true);
//   };

//   const handleSave = async () => {
//     if (editor) {
//       const canvas = editor.getImage();
//       const dataUrl = canvas.toDataURL();

//       try {
//         const response = await axios.post("/api/user/saveProfilePicture", {
//           dataUrl,
//         });

//         const newProfilePicture = response.data.profilePicture;

//         setUser((prevUser) => ({
//           ...prevUser,
//           profilePicture: newProfilePicture,
//         }));

//         setImage(null);
//         setEditor(null);
//       } catch (error) {
//         console.error("Error saving profile picture:", error);
//       }
//     }
//   };

//   const handleEdit = (field) => {
//     console.log("f ::" ,editorFieldToKey(field))
//     setEditedField(field);
//     setEditorState(user[editorFieldToKey(field)]);
//   };

//   const editorFieldToKey = (field) => {
//     let key = null;
//     switch(field) {
//       case "aboutMe":
//         key = "about";
//         break;
//       case "Experience":
//         key = "experience";
//         break;
//       case "Education":
//         key = "education";
//         break;
//       case "Skills":
//         key = "skills";
//         break;
//     }
//     return key;
//   }

//   const handleSaveText = () => {
//     // Handle saving the text to the corresponding field (experience, education, skills, about me)
//     // logic to update the user's data
//     console.log(`Saving text for ${editedField}: ${editorState}`);

//     authCtx.updateUser({
//       [editorFieldToKey(editedField)]: editorState
//     });

//   };

//   if (loading) {
//     return (
//       <div
//         style={{
//           display: "flex",
//           justifyContent: "center",
//           alignItems: "center",
//           height: "50vh",
//         }}
//       >
//         <CircularProgress />
//       </div>
//     );
//   }

//   return (
//     <RootContainer>
//       <div
//         style={{
//           display: "flex",
//           flexDirection: "column",
//           alignItems: "center",
//           marginBottom: "20px",
//         }}
//       >
//         <Avatar
//           alt={user.name}
//           src={user.avatar}
//           sx={{ width: 120, height: 120, margin: 2 }}
//         />
//         <Typography variant="h3" style={{ marginTop: "10px" }}>
//           {user.name}
//         </Typography>
//       </div>

//       <Button variant="contained" color="secondary" onClick={handleMenuOpen}>
//         User Menu
//       </Button>
//       <Menu
//         anchorEl={anchorEl}
//         open={Boolean(anchorEl)}
//         onClose={(e) => handleMenuClose(e)}
//       >
//         <MenuItem onClick={(e) => handleMenuClose(e, "ChangeProfilePicture")}>
//           Change Profile Picture
//         </MenuItem>
//         <MenuItem onClick={handleMenuClose}>Edit Profile</MenuItem>
//         <MenuItem onClick={handleMenuClose}>Logout</MenuItem>
//       </Menu>

//       <section>
//         <div {...getRootProps()} style={{ cursor: "pointer" }}>
//           <input {...getInputProps()} id="dropzone-input" />
//           <Typography variant="h5">
//             <span>About Me</span>
//             <IconButton onClick={() => handleEdit("aboutMe")}>
//               <EditIcon />
//             </IconButton>
//           </Typography>
//           <ul>
//             {/* {(user.aboutMe || []).map((exp) => (
//               <li key={exp}>{exp}</li>
//             ))} */}
//             {user.about}
//           </ul>
//           <Typography variant="h5">
//             <span>Experience</span>
//             <IconButton onClick={() => handleEdit("Experience")}>
//               <EditIcon />
//             </IconButton>
//           </Typography>
//           <ul>
//             {/* {(user.experience || []).map((exp) => (
//               <li key={exp}>{exp}</li>
//             ))} */}
//             {user.experience}
//           </ul>

//           <Typography variant="h5">
//             <span>Skills</span>
//             <IconButton onClick={() => handleEdit("Skills")}>
//               <EditIcon />
//             </IconButton>
//           </Typography>
//           <ul>
//             {/* {(user.skills || []).map((skill) => (
//               <li key={skill}>{skill}</li>
//             ))} */}
//             {user.skills}
//           </ul>

//           <Typography variant="h5">
//             <span>Education</span>
//             <IconButton onClick={() => handleEdit("Education")}>
//               <EditIcon />
//             </IconButton>
//           </Typography>
//           <ul>
//             {/* {(user.education || []).map((edu) => (
//               <li key={edu}>{edu}</li>
//             ))} */}
//             {user.education}
//           </ul>
//         </div>

//         {editedField && (
//           <div
//             style={{
//               position: "fixed",
//               bottom: "20px",
//               left: "50%",
//               transform: "translateX(-50%)",
//             }}
//           >
//             <ReactQuill
//               value={editorState}
//               onChange={(value) => setEditorState(value)}
//               modules={modules} // Pass the modules configuration to include the emoji button
//               style={{ backgroundColor: "white", width: "500px" }}
//             />
//             <Button
//               variant="contained"
//               color="primary"
//               onClick={handleSaveText}
//             >
//               Save Text
//             </Button>
//           </div>
//         )}
//       </section>

//       <Modal open={isModalOpen} onClose={closeModal}>
//         <ImageEditorContainer>
//           <CloseButton onClick={closeModal}>
//             <CloseIcon />
//           </CloseButton>
//           {image && (
//             <AvatarEditor
//               ref={(editor) => setEditor(editor)}
//               image={image}
//               width={300}
//               height={300}
//               border={50}
//               color={[255, 255, 255, 0.6]}
//               scale={1.2}
//               rotate={0}
//               style={{
//                 position: "absolute",
//                 top: "50%",
//                 left: "50%",
//                 transform: "translate(-50%, -50%)",
//               }}
//             />
//           )}
//           {image && (
//             <SaveButtonContainer>
//               <Button variant="contained" color="primary" onClick={handleSave}>
//                 Save Profile Picture
//               </Button>
//             </SaveButtonContainer>
//           )}
//         </ImageEditorContainer>
//       </Modal>
//     </RootContainer>
//   );
// };

const EditableFields = {
  about: "about",
  skills: "skills",
  education: "education",
  experience: "experience",
};

const UserProfile = () => {
  const authCtx = useAuth();
  const aboutContainerRef = useRef(null);
  const experienceContainerRef = useRef(null);
  const skillsContainerRef = useRef(null);
  const educationContainerRef = useRef(null);

  const [editor, setEditor] = useState(null);

  var timeout = {};

  const [editActivated, setEditActivated] = useState(null);
  const [editorState, setEditorState] = useState("");

  const handleAvatarChange = async (e) => {
    if(e.target.files.length) {
      let file = e.target.files[0];
      if(["image/jpeg", "image/png", "image/jpg"].includes(file.type)) {
        await authCtx.updateAvatarImage(file);
        window.location.reload();  
      }
      else
      {
        window.alert("wrong file type, please select a png or jpg images.");
      }
    }
  }

  const modules = {
    toolbar: [
      [{ font: [] }, { header: [] }],
      ["bold", "italic", "underline", "strike", "blockquote", "code-block"],
      [{ color: [] }, { background: [] }],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      [{ align: [] }],
      ["emoji"],
      ["link", "image"],
      ["clean"],
    ],
    "emoji-toolbar": true,
    "emoji-textarea": true,
    "emoji-shortname": true,
  };

  const handleEdit = (field) => {
    if (editActivated === field) {
      setEditActivated(null);
      setEditorState("");
      return;
    }
    setEditActivated(field);
    setEditorState(authCtx.user[field]);
  };

  useEffect(() => {
    if (editActivated) {
      if (timeout[editActivated]) {
        clearInterval(timeout[editActivated]);
      }
      timeout[editActivated] = setTimeout(() => {
        authCtx.updateUser({
          [editActivated]: editorState,
        });
      }, 600);
    }
  }, [editorState]);

  const [isDarkMode, setIsDarkMode] = useState(true);

  useEffect(() => {
    if (authCtx.user) {
      if (aboutContainerRef.current) {
        let about = authCtx.user.about || "<i>Not entered.</i>";
        aboutContainerRef.current.innerHTML = about;
      }

      if (experienceContainerRef.current) {
        let exp = authCtx.user.experience || "<i>Not entered.</i>";
        experienceContainerRef.current.innerHTML = exp;
      }

      if (skillsContainerRef.current) {
        let sk = authCtx.user.skills || "<i>Not entered.</i>";
        skillsContainerRef.current.innerHTML = sk;
      }

      if (educationContainerRef.current) {
        let edu = authCtx.user.education || "<i>Not entered.</i>";
        educationContainerRef.current.innerHTML = edu;
      }
    }
  }, [authCtx.user]);

  return (
    <>
      <Typography
        variant="h4"
        style={{
          textDecoration: "underline",
          fontFamily: "Arial, sans-serif",
          textAlign: "center",
          position: "relative",
          top: "20px",
          color: "white",
        }}
      >
        My Profile
      </Typography>
      <div
        className={`user-profile-wrapper ${
          isDarkMode ? "dark-mode" : "light-mode"
        }`}
      >
        <Box
          sx={{ position: "absolute", top: 80, left: 20, zIndex: "tooltip" }}
        >
          <FormControlLabel
            control={
              <Switch
                checked={isDarkMode}
                onChange={() => setIsDarkMode(!isDarkMode)}
              />
            }
            label={isDarkMode ? "Dark Mode" : "Light Mode"}
          />
        </Box>
        <div className="inner">
          <div className="user-profile-info">
            <div className="inner">
              <div className="user-profile-img h-auto">
                <div style={{position: 'relative', width: 120, height: 120, overflow: "hidden", display: "flex", justifyContent: "center", alignItems: "center"}}>
                  <Avatar
                    alt={authCtx.user.name}
                    src={authCtx.user.avatar}
                    sx={{ width: 120, height: 120, margin: 2 }}
                    title={"Change your profile picture"}
                  />
                  <AvatarUploaderInputContainer>
                    <PencilIcon width={40} height={40} />
                    <input onChange={handleAvatarChange} type="file" />
                  </AvatarUploaderInputContainer>
                </div>

                <Typography variant="h4" style={{ marginTop: "10px" }}>
                  {authCtx.user.name}
                </Typography>
              </div>
              <div className="user-profile-desc" style={{ marginTop: "20px" }}>
                <div className="h-auto">
                  <Typography variant="h6">
                    <span>ABOUT ME</span>
                    <IconButton
                      onClick={() => handleEdit(EditableFields.about)}
                      style={{ color: "white" }}
                    >
                      {editActivated === EditableFields.about ? (
                        <CloseIcon />
                      ) : (
                        <EditIcon />
                      )}
                    </IconButton>
                  </Typography>
                </div>
                <div className="about-container">
                  {editActivated === EditableFields.about ? (
                    <ReactQuill
                      value={editorState}
                      onChange={(value) => setEditorState(value)}
                      modules={modules}
                      style={{
                        backgroundColor: "white",
                        color: "#000",
                        height: "200px",
                      }}
                    />
                  ) : (
                    <div
                      ref={aboutContainerRef}
                      className="about-container-inner"
                    ></div>
                  )}
                </div>
                <div className="about-footer">
                  <Button
                    style={{ marginRight: 12 }}
                    fullWidth={true}
                    variant="contained"
                    color="secondary"
                  >
                    ADD FRIEND
                  </Button>
                  <Button
                    fullWidth={true}
                    variant="contained"
                    color="secondary"
                  >
                    SETTINGS
                  </Button>
                </div>
              </div>
            </div>
          </div>

          <div className="user-profile-extra">
            <div className="inner">
              <div className="user-profile-desc" style={{ marginTop: "20px" }}>
                <div className="h-auto">
                  <Typography variant="h6">
                    <span>EXPERIENCE</span>
                    <IconButton
                      onClick={() => handleEdit(EditableFields.experience)}
                      style={{ color: "white" }}
                    >
                      {editActivated === EditableFields.experience ? (
                        <CloseIcon />
                      ) : (
                        <EditIcon />
                      )}
                    </IconButton>
                  </Typography>
                </div>
                <div className="about-container">
                  {editActivated === EditableFields.experience ? (
                    <ReactQuill
                      value={editorState}
                      onChange={(value) => setEditorState(value)}
                      modules={modules}
                      style={{
                        backgroundColor: "white",
                        color: "#000",
                        height: "200px",
                      }}
                    />
                  ) : (
                    <div
                      ref={experienceContainerRef}
                      className="about-container-inner"
                    ></div>
                  )}
                </div>
              </div>

              <div className="user-profile-desc" style={{ marginTop: "20px" }}>
                <div className="h-auto">
                  <Typography variant="h6">
                    <span>SKILLS</span>
                    <IconButton
                      onClick={() => handleEdit(EditableFields.skills)}
                      style={{ color: "white" }}
                    >
                      {editActivated === EditableFields.skills ? (
                        <CloseIcon />
                      ) : (
                        <EditIcon />
                      )}
                    </IconButton>
                  </Typography>
                </div>
                <div className="about-container">
                  {editActivated === EditableFields.skills ? (
                    <ReactQuill
                      value={editorState}
                      onChange={(value) => setEditorState(value)}
                      modules={modules}
                      style={{
                        backgroundColor: "white",
                        color: "#000",
                        height: "200px",
                      }}
                    />
                  ) : (
                    <div
                      ref={skillsContainerRef}
                      className="about-container-inner"
                    ></div>
                  )}
                </div>
              </div>

              <div className="user-profile-desc" style={{ marginTop: "20px" }}>
                <div className="h-auto">
                  <Typography variant="h6">
                    <span>EDUCATION</span>
                    <IconButton
                      onClick={() => handleEdit(EditableFields.education)}
                      style={{ color: "white" }}
                    >
                      {editActivated === EditableFields.education ? (
                        <CloseIcon />
                      ) : (
                        <EditIcon />
                      )}
                    </IconButton>
                  </Typography>
                </div>
                <div className="about-container">
                  {editActivated === EditableFields.education ? (
                    <ReactQuill
                      value={editorState}
                      onChange={(value) => setEditorState(value)}
                      modules={modules}
                      style={{
                        backgroundColor: "white",
                        color: "#000",
                        height: "200px",
                      }}
                    />
                  ) : (
                    <div
                      ref={educationContainerRef}
                      className="about-container-inner"
                    ></div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <Modal open={editAvatarModal} onClose={() => setEditAvatarModal(false)}>
        <div></div> */}
      {/* <ImageEditorContainer>
          <CloseButton onClick={() => setEditAvatarModal(false)}>
            <CloseIcon />
          </CloseButton>
          {(
            <AvatarEditor
              ref={(editor) => setEditor(editor)}
              image={authCtx.user.avatar}
              width={300}
              height={300}
              border={50}
              color={[255, 255, 255, 0.6]}
              scale={1.2}
              rotate={0}
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            />
          )}
          {(
            <SaveButtonContainer>
              <Button variant="contained" color="primary">
                Save Profile Picture
              </Button>
            </SaveButtonContainer>
          )}
        </ImageEditorContainer> */}
      {/* </Modal> */}
    </>
  );
};

export default UserProfile;
