/* eslint-disable */

import React, { useContext, useEffect } from "react";
import Post from "./Post/Post";
import useStyles from "./Posts styles";
import { Container, IconButton, SvgIcon, Typography } from "@mui/material";
import { withStyles } from "@mui/styles";
import { useAuth } from "../../context/Auth";
import { ThemeContext } from "./Post/ThemeContext";
import WbSunnyIcon from "@mui/icons-material/WbSunny";

const SunIcon = (props) => (
  <WbSunnyIcon style={{ color: "#f44336" }} {...props} />
);

const MoonIcon = (props) => (
  <SvgIcon {...props}>
    <path fill="#f44336" d="M21 12.79A9 9 0 1 1 11.21 3 7 7 0 0 0 21 12.79z" />
  </SvgIcon>
);

// Define a IconButton
const RedIconButton = withStyles({
  root: {},
})(IconButton);

const Posts = ({ posts }) => {
  const classes = useStyles();
  const authCtx = useAuth();

  const { darkMode, toggleDarkMode } = useContext(ThemeContext);

  return (
    <Container
      className={darkMode ? classes.postsContainerDark : classes.postsContainer}
    >
      {(posts || []).map((post, index) => (
        <Post key={index} post={post} />
      ))}
      {(posts || []).length < 1 && (
        <Container style={{ padding: "30px 0" }}>
          <Typography variant="h3" color={"#ffffff"}>
            No posts found.
          </Typography>
        </Container>
      )}
      {/* <RedIconButton onClick={toggleDarkMode} style={{ position: 'fixed', bottom: 0, left: 0 }}>
        {darkMode ? <SunIcon /> : <MoonIcon />}
      </RedIconButton> */}
    </Container>
  );
};

export default Posts;
