/* eslint-disable */

import { createContext, useContext, useEffect, useReducer } from "react";
import { useCookies } from "react-cookie";
import { useLocation } from "react-router-dom";
import { APIHost } from "../conf";

const initialState = {
  initialized: false,
  authenticated: false,
  user: null,
  feed_posts: [],
  user_posts: [],
  conversations: [],
  // to message
  suggested_profiles: [],
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { user } = action.payload;
    return {
      ...state,
      user: user,
      initialized: true,
      authenticated: user ? true : false,
    };
  },
  FEED_POSTS: (state, action) => {
    const { feed_posts } = action.payload;
    return {
      ...state,
      feed_posts: feed_posts,
    };
  },
  USER_POSTS: (state, action) => {
    const { user_posts } = action.payload;
    return {
      ...state,
      user_posts: user_posts,
    };
  },
  USER_CONVERSATIONS: (state, action) => {
    const { conversations } = action.payload;
    return {
      ...state,
      conversations: conversations,
    };
  },
  SUGGESTED_PROFILES: (state, action) => {
    const { profiles } = action.payload;
    return {
      ...state,
      suggested_profiles: profiles,
    };
  },
};

const reducer = (state, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

const AuthCtx = createContext({
  ...initialState,
  post: async (text, imgurl) => {},
  logout: () => {},
  initUserPosts: () => {},
  initUserConversations: () => {},
  setConversations: (cvs, keepLast = true) => {},
  loadSuggestedProfiles: () => {},
  likePost: async (postId) => {},
  replyPost: async (postId, msg) => {},
  sendMessage: async (profileId, text, img) => {},
  followProfile: async (profileId) => {},
  fetchMessageHistory: async (profileId) => {},
  updateUser: (data) => {},
  uploadImage: async (img, is_url) => {},
  updateAvatarImage: async (imgfiledata) => {}
});

export function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [cookies, setCookie, removeCookie] = useCookies(["login_token"]);

  const checkForLogin = () => {
    const queryParams = new URLSearchParams(window.location.search);
    const token = queryParams.get("t");

    if (token) {
      let d = new Date(new Date().setDate(new Date().getDate() + 60));
      setCookie("login_token", token, {
        expires: d,
      });
      setTimeout(() => {
        window.location.href = "/";
      }, 200);
    }
  };

  const fetchUser = async (token) => {
    let resp = await fetch(`${APIHost}/user`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (resp.status === 200) {
      let b = await resp.json();
      return b;
    }
    return null;
  };

  const updateUser = async (data) => {
    let token = cookies["login_token"] || null;
    let resp = await fetch(`${APIHost}/user`, {
      headers: {
        Authorization: token ? `Bearer ${token}` : undefined,
        "content-type": "application/json",
      },
      method: "PUT",
      body: JSON.stringify(data),
    });
    if (resp.status === 200) {
      let b = await resp.json();
      await initialize();
      return b;
    }
    return null;
  };

  const fetchFeedPosts = async () => {
    let token = cookies["login_token"] || null;
    let resp = await fetch(`${APIHost}/post`, {
      headers: {
        Authorization: token ? `Bearer ${token}` : undefined,
      },
    });
    if (resp.status === 200) {
      let b = await resp.json();
      return b;
    }
    return null;
  };

  const fetchUserPosts = async () => {
    let token = cookies["login_token"] || null;
    let resp = await fetch(
      `${APIHost}/post/user/${state.user ? state.user._id : ""}`,
      {
        headers: {
          Authorization: token ? `Bearer ${token}` : undefined,
        },
      }
    );
    if (resp.status === 200) {
      let b = await resp.json();
      return b;
    }
    return null;
  };

  const fetchUserConversations = async () => {
    let token = cookies["login_token"] || null;
    let resp = await fetch(`${APIHost}/message/conversations`, {
      headers: {
        Authorization: token ? `Bearer ${token}` : undefined,
      },
    });
    if (resp.status === 200) {
      let b = await resp.json();
      return b;
    }
    return null;
  };

  const fetchSuggestedProfiles = async () => {
    let token = cookies["login_token"] || null;
    let resp = await fetch(`${APIHost}/profile/all`, {
      headers: {
        Authorization: token ? `Bearer ${token}` : undefined,
      },
    });
    if (resp.status === 200) {
      let b = await resp.json();
      return b;
    }
    return null;
  };

  const toggleLikePost = async (postId) => {
    let token = cookies["login_token"] || null;
    let resp = await fetch(`${APIHost}/post/like/${postId}`, {
      headers: {
        Authorization: token ? `Bearer ${token}` : undefined,
      },
      method: "put",
    });
    if (resp.status === 200) {
      let b = await resp.json();
      return b;
    }
    return null;
  };

  const doReplyPost = async (postId, replyMsg) => {
    let token = cookies["login_token"] || null;
    let resp = await fetch(`${APIHost}/post/reply/${postId}`, {
      headers: {
        Authorization: token ? `Bearer ${token}` : undefined,
      },
      method: "put",
      body: JSON.stringify({
        text: replyMsg,
      }),
    });
    if (resp.status === 200) {
      let b = await resp.json();
      return b;
    }
    return null;
  };

  const toggleFollowProfile = async (profileId) => {
    let token = cookies["login_token"] || null;
    let resp = await fetch(`${APIHost}/profile/follow/${profileId}`, {
      headers: {
        Authorization: token ? `Bearer ${token}` : undefined,
      },
      method: "post",
    });
    if (resp.status === 200) {
      let b = await resp.json();
      return b;
    }
    return null;
  };

  const doSendMessage = async (profileId, msgText) => {
    let token = cookies["login_token"] || null;
    let resp = await fetch(`${APIHost}/message`, {
      headers: {
        Authorization: token ? `Bearer ${token}` : undefined,
        "Content-Type": "application/json",
      },
      method: "post",
      body: JSON.stringify({
        recipentId: profileId,
        message: msgText,
        img: undefined,
      }),
    });
    if (resp.status === 200 || resp.status === 201) {
      let b = await resp.json();
      return b;
    }
    return null;
  };

  const doFetchMessagesHistory = async (profileId) => {
    let token = cookies["login_token"] || null;
    let resp = await fetch(`${APIHost}/message/${profileId}`, {
      headers: {
        Authorization: token ? `Bearer ${token}` : undefined,
        "Content-Type": "application/json",
      },
      method: "get",
    });
    if (resp.status === 200) {
      let b = await resp.json();
      return b;
    }
    return null;
  };

  const initialize = () => {
    if (cookies["login_token"]) {
      fetchUser(cookies["login_token"]).then((user) => {
        dispatch({
          type: "INITIALIZE",
          payload: {
            user: {
              ...user,
              following: user?.following || [],
              followers: user?.followers || [],
            },
          },
        });
      });
    } else {
      dispatch({
        type: "INITIALIZE",
        payload: {
          user: null,
        },
      });
    }
  };

  const likePost = async (postId) => {
    await toggleLikePost(postId);
  };

  const replyPost = async (postId, msg) => {
    await doReplyPost(postId, msg);
  };

  const sendMessage = async (profileId, text, img) => {
    return await doSendMessage(profileId, text);
  };

  const fetchMessageHistory = async (profileId) => {
    return await doFetchMessagesHistory(profileId);
  };

  const followProfile = async (profileId) => {
    await toggleFollowProfile(profileId);
    initialize();
  };

  const initFeeds = async () => {
    let posts = await fetchFeedPosts();
    console.log("posts ::: ", posts);
    dispatch({
      type: "FEED_POSTS",
      payload: {
        feed_posts: posts,
      },
    });
  };

  const initUserPosts = async () => {
    let posts = await fetchUserPosts();
    console.log("posts ::: ", posts);
    dispatch({
      type: "USER_POSTS",
      payload: {
        user_posts: posts,
      },
    });
  };

  const initUserConversations = async () => {
    let conversations = await fetchUserConversations();
    console.log("conversations ::: ", conversations);
    dispatch({
      type: "USER_CONVERSATIONS",
      payload: {
        conversations: conversations,
      },
    });
  };

  const loadSuggestedProfiles = async () => {
    let ps = await fetchSuggestedProfiles();
    dispatch({
      type: "SUGGESTED_PROFILES",
      payload: {
        profiles: ps,
      },
    });
  };

  const setConversations = (cvs, keepLast = true) => {
    dispatch({
      type: "USER_CONVERSATIONS",
      payload: {
        conversations: [...(keepLast ? state.conversations : []), ...cvs],
      },
    });
  };

  const getAuth = () => {
    if (cookies["login_token"]) {
      return `Bearer ${cookies["login_token"]}`;
    }
    return undefined;
  };

  const post = async (text, imgurl) => {
    try {
      const response = await fetch(`${APIHost}/post/create`, {
        method: "POST",
        headers: {
          Authorization: getAuth(),
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          text: text,
          img: (imgurl || "").length > 0 ? imgurl : undefined,
        }),
      });

      if (response.ok) {
        const postData = await response.json();
        console.log("Post created:", postData);
        return true;
      } else {
        const errorData = await response.json();
        console.error("Error creating post:", errorData);
        return false;
      }
    } catch (error) {
      console.error("Error creating post:", error);
    }
  };

  const updateAvatarImage = async (imgFileData) => {
    let formdata = new FormData();
    formdata.append("file", imgFileData);
    var response = await fetch(`${APIHost}/user/avatar`, {
      method: "PUT",
      headers: {
        Authorization: getAuth(),
      },
      body: formdata,
    });
    if (response.ok) {
      return true;
    }
    return false;
  };

  const uploadImage = async (img, is_url) => {
    try {
      var response = null;

      if (is_url) {
        response = await fetch(`${APIHost}/post/image-uploader`, {
          method: "POST",
          headers: {
            Authorization: getAuth(),
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            img: img,
          }),
        });
      } else {
        let formdata = new FormData();
        formdata.append("file", img);
        response = await fetch(`${APIHost}/post/image-uploader`, {
          method: "POST",
          headers: {
            Authorization: getAuth(),
          },
          body: formdata,
        });
      }

      if (response.ok) {
        const postData = await response.json();
        return {
          url: postData.uploaded_url,
        };
      } else {
        const errorData = await response.json();
        console.error("Error uploading imagr:", errorData);
        return false;
      }
    } catch (error) {
      console.error("Error creating post:", error);
    }
  };

  const logout = async () => {
    removeCookie("login_token");
    setTimeout(() => {
      window.location.href = "/";
    }, 1000);
  };

  useEffect(() => {
    checkForLogin();
    initialize();
    initFeeds();
  }, []);

  return (
    <>
      <AuthCtx.Provider
        value={{
          ...state,
          post,
          logout,
          initUserPosts,
          initUserConversations,
          setConversations,
          loadSuggestedProfiles,
          likePost,
          followProfile,
          replyPost,
          sendMessage,
          fetchMessageHistory,
          updateUser,
          uploadImage,
          updateAvatarImage
        }}
      >
        {state.initialized ? (
          <>{children}</>
        ) : (
          <>
            <>Loading...</>
          </>
        )}
      </AuthCtx.Provider>
    </>
  );
}

export const useAuth = () => {
  const context = useContext(AuthCtx);
  if (!context) {
    // u didn't put the provider
  }
  return context;
};

export const AuthGuard = ({ children }) => {
  const authCtx = useAuth();

  return (
    <>
      {authCtx.authenticated ? (
        children
      ) : (
        <>
          <h1>U NEED TO LOGIN</h1>
        </>
      )}
    </>
  );
};

export default AuthCtx;
