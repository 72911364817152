/* eslint-disable */

import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import authConfig from '../authConfig';
import './AzureADLogin.css';

function AzureADRegister() {
  const navigate = useNavigate();

  useEffect(() => {
    // Check if the URL contains the expected code and state parameters
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has('code') && urlParams.has('state')) {
      console.log("u ::: ", urlParams)
      // Handle the callback here (e.g., exchange the code for tokens)
      // After handling the callback, you can redirect the user to a specific page in your app
      navigate('/profile'); // Replace with the desired redirect URL
    }
  }, [navigate]);

  // Function to initiate Azure AD B2C registration
  const handleRegisterClick = () => {
    const azureADRegisterUrl = `${authConfig.authority}/oauth2/v2.0/authorize?p=B2C_1_auth.vernetzung&client_id=${authConfig.clientId}&nonce=defaultNonce&redirect_uri=${encodeURIComponent(authConfig.redirectUri)}&scope=openid&response_type=id_token&prompt=login`;

    // Redirect the user to the Azure AD B2C registration page
    window.location.href = azureADRegisterUrl;
  };

  return (
    <div className="button-container">
      {/* Keep the custom button's CSS and apply the handleRegisterClick logic */}
      <button onClick={handleRegisterClick} className="custom-button">SIGN UP</button>
    </div>
  );
}

export default AzureADRegister;
