/* eslint-disable */

import React, { useContext, useEffect, useRef } from "react";
import {
  Avatar,
  Button,
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  Container,
  Typography,
} from "@mui/material";
import useStyles from "./Post styles";
import "../../../scss/post.scss";
import AuthCtx from "../../../context/Auth";
import { useNavigate } from "react-router-dom";
import { ThemeContext } from "./ThemeContext";
import EditorJS from "@editorjs/editorjs";
import ImageTool from '@editorjs/image';

const Post = ({ post }) => {
  const classes = useStyles();
  const { darkMode } = useContext(ThemeContext);

  const editorJsRef = useRef(null);

  const authCtx = React.useContext(AuthCtx);
  const navigateTo = useNavigate();

  const handleFollowProfile = async () => {
    await authCtx.followProfile(post.owner._id);
  };

  const handleMessage = async () => {
    navigateTo(
      `/messages/${post.owner._id}__profile_id?name=${post.owner.name}`
    );
  };

  useEffect(() => {
    let t = "";
    try {
      t = JSON.parse(post.text);
      if(t) {
        if(editorJsRef.current) {
          editorJsRef.current.destory();
        }
        editorJsRef.current = new EditorJS({
          holder: `post-content-preview-${post._id}`,
          data: t,
          minHeight: 0,
          readOnly: true,
          tools: {
            image: {
              class: ImageTool
            }
          }
        });
      }
    } catch (error) {}
  }, []);

  return (
    <Card
      className={classes.postContainer}
      style={{
        backgroundColor: darkMode ? "#161616" : "#fff",
        color: darkMode ? "#fff" : "#000",
      }}
    >
      <div className={classes.postHeader}>
        <div className={classes.postHeaderInner}>
          <Avatar className={classes.postHeaderImage} src={post.owner.avatar} />
          <Typography variant="body1">{post.owner.name}</Typography>
        </div>
        <div className={classes.postHeaderInner} style={{ width: "auto" }}>
          {authCtx.user?._id === post.owner._id ? <></> : <>
          <Button onClick={handleMessage} variant="text">
            Message
          </Button>
          <Button onClick={handleFollowProfile} variant="text">
            {(authCtx.user?.following || []).includes(post.owner._id)
              ? "Unfollow"
              : "Follow"}
          </Button>
          </>}
        </div>
      </div>
      <CardContent>
        <Container>
          <div id={`post-content-preview-${post._id}`}></div>
          {/* <Typography variant="body1" className={classes.postContent}>
          {post.text}
        </Typography> */}
        </Container>
        {post.img && (
          <CardMedia
            component="img"
            alt="Post Media"
            src={post.img}
            className={classes.postImage}
          />
        )}
      </CardContent>
    </Card>
  );
};

export default Post;
