/* eslint-disable */

import styled from "@emotion/styled";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Container,
  Grid,
  Button,
  Divider,
  Typography,
} from "@mui/material";
import Posts from "../components/Posts/Posts";
import { useAuth } from "../context/Auth";
import { useEffect, useRef, useState } from "react";
import EditorJS from "@editorjs/editorjs";
import ImageTool from "@editorjs/image";

const AppContainer = styled(Container)(({ theme }) => ({
  marginTop: theme.spacing(2),
  maxHeight: "calc(100vh - 64px)", // Adjust based on your header's height
  overflowY: "auto",
}));

const CreatePost = ({ open, handleClose }) => {
  const authCtx = useAuth();
  const editorRef = useRef(null);

  useEffect(() => {
    try {
      if (open) {
        if (editorRef.current) {
          editorRef.current.destroy();
        }
        editorRef.current = new EditorJS({
          holder: "post-content-writer",
          placeholder: "What's in your mind?",
          tools: {
            image: {
              class: ImageTool,
              config: {
                uploader: {
                  uploadByFile(file) {
                    return authCtx.uploadImage(file, false).then((res) => {
                      if (res) {
                        return {
                          success: 1,
                          file: {
                            url: res.url,
                          },
                        };
                      }
                      return { success: 0 };
                    });
                  },
                  uploadByUrl(url) {
                    return authCtx.uploadImage(url, true).then((res) => {
                      if (res) {
                        return {
                          success: 1,
                          file: {
                            url: res.url,
                          },
                        };
                      }
                      return { success: 0 };
                    });
                  },
                },
                // endpoints: {
                //   byFile: 'http://localhost:8008/uploadFile', // Your backend file uploader endpoint
                //   byUrl: 'http://localhost:8008/fetchUrl' // Your endpoint that provides uploading by Url
                // }
              },
            },
          },
        });
      }
    } catch (error) {}
  }, [open]);

  const handleCreate = (e) => {
    editorRef.current.save().then((v) => {
      let value_as_text = JSON.stringify(v);
      authCtx.post(value_as_text, null).then((r) => {
        handleClose();
        window.location.reload();
      });
    });
  };
  return (
    <>
      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle>Create new Post</DialogTitle>
        <DialogContent dividers>
          <div id="post-content-writer"></div>
        </DialogContent>
        <DialogActions style={{ gap: 14 }}>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleCreate} variant="contained" color="primary">
            Create
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const PostsPage = () => {
  const authCtx = useAuth();
  const handleCreateNewPost = (e) => {
    handleOpen();
  };

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <Container maxWidth="md" style={{ padding: "0 50px" }}>
        <Grid
          container
          justifyContent={"space-between"}
          alignItems={"stretch"}
          marginTop={4}
        >
          <div>
            <Typography color={"#fff"} variant="h5">
              Feeds
            </Typography>
          </div>
          <div>
            <Button onClick={handleCreateNewPost} variant={"contained"}>
              Create post
            </Button>
          </div>
        </Grid>
      </Container>
      <AppContainer maxWidth="md">
        <Posts posts={authCtx.feed_posts} />
      </AppContainer>
      <Divider />
      <CreatePost open={open} handleClose={handleClose} />
      {/* <Divider />
        <Grow in>
          <Container>
            <Grid
              container
              justify="space-between"
              alignItems="stretch"
              spacing={3}
            >
              <Grid item xs={12} sm={7}>
              </Grid>
              <Grid item xs={12} sm={4}>
                {authCtx.authenticated ? <Form /> : <h1>Login to create post</h1>}
              </Grid>
            </Grid>
          </Container>
        </Grow> */}
    </>
  );
};

export const MyPostsPage = () => {
  const authCtx = useAuth();

  useEffect(() => {
    authCtx.initUserPosts();
  }, []);

  const handleCreateNewPost = (e) => {
    handleOpen();
  };

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Container maxWidth="md" style={{ padding: "0 50px" }}>
        <Grid
          container
          justifyContent={"space-between"}
          alignItems={"stretch"}
          marginTop={4}
        >
          <div>
            <Typography color={"#fff"} variant="h5">
              My posts
            </Typography>
          </div>
          <div>
            <Button onClick={handleCreateNewPost} variant={"contained"}>
              Create post
            </Button>
          </div>
        </Grid>
      </Container>
      <AppContainer maxWidth="md">
        <Posts posts={authCtx.user_posts} />
      </AppContainer>
      <Divider />
      <CreatePost open={open} handleClose={handleClose} />
      {/* <Divider />
          <Grow in>
            <Container>
              <Grid
                container
                justify="space-between"
                alignItems="stretch"
                spacing={3}
              >
                <Grid item xs={12} sm={7}>
                </Grid>
                <Grid item xs={12} sm={4}>
                  {authCtx.authenticated ? <Form /> : <h1>Login to create post</h1>}
                </Grid>
              </Grid>
            </Container>
          </Grow> */}
    </>
  );
};

export default PostsPage;
