/* eslint-disable */

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  postContainer: {
    border: '1px solid #ddd',
    borderRadius: '8px',
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    backgroundColor: '#fff',
  },
  postContainerDark: {
    border: '1px solid #ddd',
    borderRadius: '8px',
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    backgroundColor: '#333',
    color: '#fff',
  },
  postContent: {
    fontSize: '16px',
    lineHeight: '1.5',
  },
  postImage: {
    maxWidth: '100%',
    maxHeight: '400px',
    objectFit: 'cover',
    borderRadius: '4px',
    marginTop: theme.spacing(2),
  },
  postHeader: {
    padding: theme.spacing(2),
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  postHeaderInner: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  postHeaderImage: {
    marginRight: 16
  }
}));

export default useStyles;