/* eslint-disable */

import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { PublicClientApplication } from "@azure/msal-browser";
import { CookiesProvider } from "react-cookie";
import { AuthProvider } from "./context/Auth";
import { SocketContextProvider } from "./context/Socket";

const theme = createTheme();

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <>
    {/* <React.StrictMode> */}
    <CookiesProvider>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <AuthProvider>
          <SocketContextProvider>
            <App />
          </SocketContextProvider>
        </AuthProvider>
      </ThemeProvider>
    </CookiesProvider>
    {/* </React.StrictMode> */}
  </>
);
