/* eslint-disable */

import React, { useState, useEffect } from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { CircularProgress, AppBar, Toolbar, Typography, Button } from '@mui/material';
import { useAuth } from '../context/Auth';

const PrivateRoute = ({ element: Element, ...rest }) => {
  const [loading, setLoading] = useState(true);
  const authCtx = useAuth();

  const location = useLocation();

  useEffect(() => {
    setLoading(false);
  }, []);

  if (loading) {
    return (
      <div>
        <CircularProgress />
      </div>
    );
  }

  return authCtx.authenticated ? (
    <>
      <Element />
    </>
  ) : (
    <Navigate to="/login" state={{ from: location }} replace={true} />
  );
};

export default PrivateRoute;
