/* eslint-disable */

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  postsContainer: {
    display: 'grid',
    gap: theme.spacing(2),
  },
  // Add more styles for posts container
}));

export default useStyles;
