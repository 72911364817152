/* eslint-disable */

import React, { useState, useEffect } from "react";
import { ThemeProvider } from "../src/components/Posts/Post/ThemeContext";
import AppRouter from "./Router";

const App = () => {
  return (
    <ThemeProvider>
      <AppRouter />
    </ThemeProvider>
  );
};

export default App;
