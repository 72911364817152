/* eslint-disable */

import React from "react";
import { Typography, Button, Grid } from "@mui/material";
import { Link } from "react-router-dom";
import { styled } from "@mui/material/styles";

const RootContainer = styled("div")(({ theme }) => ({
  minHeight: "calc(100vh - 64px)", // Adjust height to account for header height (assuming 64px header height)
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  textAlign: "center",
  padding: theme.spacing(3),
  backgroundColor: theme.palette.background.default,
}));

const Heading = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  color: theme.palette.primary.main,
}));

const Description = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(4),
  color: theme.palette.text.secondary,
}));

const StyledButton = styled(Button)(({ theme }) => ({
  padding: theme.spacing(1.5, 3),
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  textDecoration: "none",
  "&:hover": {
    backgroundColor: theme.palette.primary.dark,
  },
}));

const NotFound = () => {
  return (
    <RootContainer>
      <Grid container direction="column" alignItems="center">
        <Grid item>
          <Heading variant="h3">Page Not Found</Heading>
        </Grid>
        <Grid item>
          <Description variant="body1">
            Sorry, the page you are looking for does not exist.
          </Description>
        </Grid>
        <Grid item>
          <StyledButton variant="contained" component={Link} to="/">
            Go to Home
          </StyledButton>
        </Grid>
      </Grid>
    </RootContainer>
  );
};

export default NotFound;
